export enum ActionType {
    redirect = 'redirect',
    submit = 'submit',
    close = 'close',
    spin = 'spin',
    autoRedirect = 'autoRedirect',
    nextStep = 'nextStep',
    countdownTimer = 'countdownTimer',
    clickOutside = 'clickOutside'
}

export enum FieldErrorType {
    fieldMissing = 'FIELD_MISSING',
    fieldInvalid = 'FIELD_INVALID'
}

export enum DeviceType {
    mobile = 'mobile',
    desktop = 'desktop'
}

export type Field = {
    name: string;
    required: boolean;
    targetID: string;
    stepID: string;
};

type ActionSettings = {
    url?: string;
    endDateTime?: string;
    stepID?: string;
};

export type FormAction = {
    type: ActionType;
    targetID: string;
    settings?: ActionSettings;
};

export enum DisplayType {
    embedded = 'embedded',
    popup = 'popup',
    landingPage = 'landingPage',
    flyout = 'flyout'
}

export type FormData = {
    id: string;
    name: string;
    actions?: FormAction[];
    fields?: Field[];
    displayType: DisplayType;
    targeting?: Targeting;
    steps?: FormDataStep[];
    abSetupId?: string;
    mainFormId?: string;
    mainFormName?: string;
};

export type UrlTargetingRuleType = 'contains' | 'exact';

export type UrlTargetingRule = {
    type: UrlTargetingRuleType;
    value: string;
};

export type UrlTargeting = {
    includes?: UrlTargetingRule[];
    excludes?: UrlTargetingRule[];
};

export type UtmTargetingRuleType = 'id' | 'source' | 'medium' | 'campaign' | 'term' | 'content';

export type UtmTargetingRule = {
    type: UtmTargetingRuleType;
    value: string;
};

export type UtmTargeting = {
    includes?: UtmTargetingRule[];
};

export type SourceTargetingRuleType = 'googleAds' | 'organic' | 'direct' | 'facebook' | 'instagram' | 'omnisendCommunication';

export type SourceTargeting = {
    includes?: SourceTargetingRuleType[];
    excludes?: SourceTargetingRuleType[];
};

export type Targeting = {
    source?: SourceTargeting;
    utm?: UtmTargeting;
    url?: UrlTargeting;
    display?: Display;
    frequency?: number; // ms
    device?: DeviceType;
    backInStock?: boolean;
    audience?: Audience;
    serverSideTargeting?: boolean;
};

export type CustomTriggerType = 'only';

export type Display = {
    afterViewedPageCount?: number;
    afterTime?: number; // ms
    afterScrollDown?: number; // Percent
    onExit?: boolean;
    customTrigger?: CustomTriggerType;
};

export type Audience = {
    type?: AudienceType;
};

export enum AudienceType {
    subscribers = 'subscribers',
    notSubscribers = 'notSubscribers'
}

export type FormDataStep = {
    ID: string;
};

export type Step = '1' | '2';

export type FormDataValue = string | string[] | FormDataEntryValue;

export type SubscribeFormData = Record<string, FormDataValue>;

export type SubscribePayload = {
    brandID: string;
    formID: string;
    pageData: {
        URL: string;
        title: string;
        timeToSubmit?: number;
    };
    formData: SubscribeFormData;
    stepID?: string;
    contactID?: string;
    cookieContactID?: string;
    unconfirmedContactID?: string;
    correlationID?: string;
};

export type SubmitBackInStockPayload = {
    productID: string;
    variantID: string;
} & SubscribePayload;

export type SubscribeBadRequest = {
    details: Record<string, FieldErrorType>;
};

export enum SubmittedFormState {
    success = 'success',
    subscribed = 'subscribed'
}

export type SubscribeResult = {
    formState: SubmittedFormState;
    contactID: string;
    unconfirmedContactID?: string;
    discount?: Discount;
};

export type Discount = {
    sliceID: string;
    code: string;
};

export type WofSpinResult = {
    correlationID: string;
    sliceID: string;
};

export type FormContactIdentifier = {
    contactID?: string;
    unconfirmedContactID?: string;
};

export type AdditionalActions = (() => void)[];

export type ListenerOptions = {
    form: Form;
    element: HTMLElement;
    additionalActions?: AdditionalActions;
    focusLocker?: FocusLocker;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSubscribeBadRequest = (error: any): error is SubscribeBadRequest => {
    return error.details;
};

export type FocusLocker = {
    lock: (element: HTMLElement, formData: FormData) => void;
    clear: () => void;
};

export type Form = {
    data: FormData;
    element: HTMLElement;
    isMultistep: boolean;
    isBackInStock: boolean;
    getCurrentStepID: () => string;
    changeToNextStep: () => void;
    changeToFirstStep: () => void;
    isLastStep: () => boolean;
    getCurrentStepFields: () => Field[];
    getContactIdentifier: () => FormContactIdentifier;
    getLastSubscribeResult: () => SubscribeResult | null;
    saveSubscribeResult: (subscribeResult: SubscribeResult) => void;
    getDiscountCode: () => string;
    saveDiscountCode: (code: string) => void;
    getCorrelationID: () => string;
    saveCorrelationID: (id: string) => void;
    timeFormInteracted?: number;
};
