import { focusFormContent, getFocusLocker } from '../../../accessibility';
import { getContext } from '../../../context';
import {
    getFormContainerElement,
    getFormContentElement,
    getFormsContainerSelector,
    getFormTeaserButtonSelector,
    getFormTeaserSelector
} from '../../../elements';
import { attachFieldsFocusListeners, attachFormSubmitListener, attachSelectFieldsListeners } from '../../../event-listeners';
import { trackView } from '../../../tracking';
import { hideElement, isVisibleElement, markFormTeaserClosed, showElement } from '../../../utils';
import { InshopFormData } from '../types';
import { attachFormCloseListeners } from './event-listeners/close';
import { attachFormActionsListeners as attachPopupFormActionsListeners } from './event-listeners/form-actions';
import { attachGlobalListeners } from './event-listeners/global';
import { attachTeaserCloseButtonListeners, attachTeaserListeners } from './event-listeners/teaser';
import { hideFormsTeasers } from './form-teaser';
import { PopupForm } from './types';
import { createElement } from '../inshop-form';
import { createForm } from '../../../form';
import { setFormTeaserClosedInSession } from './activity-monitoring';
import { initCountdownTimer } from '../../../components/countdown-timer';
import { DisplayType } from '../../../types';
import { dispatchStepViewEvent, dispatchViewEvent } from '../../../event-dispatchers';
import { initWheelOfFortune } from '../../../components/wheel-of-fortune/wheel-of-fortune';

const isFormsContainerPresent = (): boolean => {
    const formsContainer = document.querySelector(getFormsContainerSelector());
    return !!formsContainer;
};

export function createPopupForm(formData: InshopFormData): PopupForm {
    const element = createElement(formData);
    const isFlyout = formData.displayType === DisplayType.flyout;

    const baseForm = createForm(formData, element);

    let teaserSetupCompleted = false;
    let formSetupCompleted = false;
    let formRendered = false;

    const focusLocker = getFocusLocker();

    const cleanupFocusLock = (): void => {
        if (!isFlyout) {
            focusLocker.clear();
        }
    };

    const lockFormFocus = (): void => {
        const formContainer = getFormContainerElement({ parentElement: element, formID: formData.id });

        if (!isFlyout) {
            focusLocker.lock(formContainer, formData);
        }
    };

    const renderForm = (): void => {
        if (formRendered) {
            return;
        }
        const formsContainer = document.querySelector(getFormsContainerSelector());

        element.innerHTML = formData.html;
        formsContainer.appendChild(element);

        initWheelOfFortune(formData);

        formRendered = true;
    };

    const cleanupForm = (): void => {
        const formsContainer = document.querySelector(getFormsContainerSelector());
        formsContainer.removeChild(element);

        teaserSetupCompleted = false;
        formSetupCompleted = false;
        formRendered = false;
    };

    const showForm = (): void => {
        const { forms } = getContext();

        if (!forms.checkIfWindowIsClear() || !isFormsContainerPresent()) {
            return;
        }

        forms.setWindowClearance(false);

        if (!formSetupCompleted) {
            renderForm();
        }

        const formContainer = getFormContainerElement({ parentElement: element, formID: formData.id });
        const formContent = getFormContentElement({ parentElement: formContainer, formID: formData.id });

        showElement(formContainer);

        if (!isVisibleElement(formContent)) {
            // Certain shops intentionally hide forms. To handle this, we attempt to render our form,
            // and if it remains hidden, we stop further code execution.
            cleanupForm();

            return;
        }

        if (!formSetupCompleted) {
            attachFormSubmitListener(formContainer, baseForm, [lockFormFocus]);
            attachSelectFieldsListeners(formContainer, baseForm);
            formSetupCompleted = true;
        }

        dispatchViewEvent({ formID: formData.id });
        if (formData.steps?.length > 0) {
            dispatchStepViewEvent({ formID: formData.id, step: '1' });
        }

        attachFieldsFocusListeners(formContainer, baseForm);
        attachPopupFormActionsListeners({ form: baseForm, element: formContainer, additionalActions });
        attachFormCloseListeners({ form: baseForm, element: formContainer, additionalActions });
        attachGlobalListeners({ form: baseForm, additionalActions });

        lockFormFocus();
        initCountdownTimer(formContainer, formData);
        trackView(formData);
        hideFormsTeasers();

        focusFormContent(formData.id);
    };

    const hideForm = (): void => {
        const formContainer = getFormContainerElement({ parentElement: element, formID: formData.id });
        hideElement(formContainer);
    };

    const showTeaser = (): void => {
        setupTeaser();
        showElement(element.querySelector(getFormTeaserSelector(formData.id)));
    };

    const setupTeaser = (): void => {
        if (teaserSetupCompleted || !isFormsContainerPresent()) {
            return;
        }

        renderForm();
        hideForm();

        attachTeaserListeners({ form: baseForm, element }, showForm);

        if (formData.teaser?.useCloseButton) {
            attachTeaserCloseButtonListeners({ form: baseForm, element }, closeTeaser);
        }

        teaserSetupCompleted = true;
    };

    const isTeaserVisible = (): boolean => {
        return isVisibleElement(element.querySelector(getFormTeaserButtonSelector(formData.id)));
    };

    const hideTeaser = (): void => {
        hideElement(element.querySelector(getFormTeaserSelector(formData.id)));
    };

    const closeTeaser = (): void => {
        hideTeaser();
        markFormTeaserClosed(formData.mainFormId || formData.id);
        setFormTeaserClosedInSession(formData.id);
    };

    const additionalActions = [cleanupFocusLock, cleanupForm];

    return {
        base: baseForm,
        data: formData,
        showTeaser,
        hideTeaser,
        isTeaserVisible,
        show: showForm,
        hide: hideForm
    };
}
