import { getBlockActionSelector, getFormStepElement, getFormSubscribedSections, getFormSuccessSections } from '../elements';
import { trackInteraction } from '../tracking';
import { ActionType, Form, SubmittedFormState } from '../types';
import { hideElement, showElement } from '../utils';
import { initCountdownTimer } from '../components/countdown-timer';
import { dispatchStepViewEvent } from '../event-dispatchers';
import { focusFormContent } from '../accessibility';
import { spinWheelOfFortune } from './spin';
import { spinWof } from '../api';
import { handleNextStepError, resetFormNextStepErrorState } from './submit/error-handling';
import { autoRedirectAction } from './auto-redirect';
import { initDiscountCode } from '../components/discount-code';

export const hideCurrentStep = (form: Form): void => {
    const { id: formID } = form.data;
    const currentStepID = form.getCurrentStepID();

    const currentStep = getFormStepElement({
        parentElement: document.body,
        formID,
        stepID: currentStepID
    });

    hideElement(currentStep);
};

export const showNextStep = (form: Form): void => {
    form.changeToNextStep();

    const nextStep = getFormStepElement({
        parentElement: document.body,
        formID: form.data.id,
        stepID: form.getCurrentStepID()
    });

    showElement(nextStep);
    dispatchStepViewEvent({ formID: form.data.id, step: '2' });
};

const disableNextStepButton = (formID: string, blockID: string, formElement: HTMLElement): void => {
    const nextStepButton = formElement.querySelector(getBlockActionSelector(formID, blockID));
    if (!nextStepButton) return;
    nextStepButton.setAttribute('disabled', 'true');
};

const enableNextStepButton = (formID: string, blockID: string, formElement: HTMLElement): void => {
    const nextStepButton = formElement.querySelector(getBlockActionSelector(formID, blockID));
    if (!nextStepButton) return;
    nextStepButton.removeAttribute('disabled');
};

export const nextStepAction = async (form: Form, formElement: HTMLElement): Promise<void> => {
    const { id: formID, actions } = form.data;

    const nextStepAction = actions.find((action) => action.type === ActionType.nextStep);
    if (!nextStepAction) return;

    const currentStepID = form.getCurrentStepID();

    const spinAction = actions.find((action) => action.type === ActionType.spin);
    const isWofInCurrentStep = spinAction?.settings?.stepID === currentStepID;

    resetFormNextStepErrorState(formID, formElement);
    disableNextStepButton(formID, nextStepAction.targetID, formElement);
    await trackInteraction(form);

    if (form.isLastStep() && form.isMultistep) {
        return await proceedToSubmittedState(form);
    }
    if (isWofInCurrentStep) {
        try {
            const { sliceID, correlationID } = await spinWof(form);
            form.saveCorrelationID(correlationID);
            await spinWheelOfFortune(formID, spinAction.targetID, sliceID);
        } catch {
            enableNextStepButton(formID, nextStepAction.targetID, formElement);
            handleNextStepError(formID, formElement);
            return;
        }
    }

    hideCurrentStep(form);
    showNextStep(form);
    initCountdownTimer(form.element, form.data);
    focusFormContent(formID);
};

const showFormSubmittedState = (formID: string, submittedFormState: SubmittedFormState): void => {
    if (submittedFormState == SubmittedFormState.subscribed) {
        showElement(document.querySelector(getFormSubscribedSections(formID)));
    } else {
        showElement(document.querySelector(getFormSuccessSections(formID)));
    }
};

export const proceedToSubmittedState = (form: Form): void => {
    const subscribeResult = form.getLastSubscribeResult();
    if (!subscribeResult) return;
    hideCurrentStep(form);
    showFormSubmittedState(form.data.id, subscribeResult.formState);
    autoRedirectAction(form.data.actions);
    initDiscountCode(form);
};
