import {
    getFormBlockContainerSelector,
    getFormWofPointerSelector,
    getFormWofPointerShadowSelector,
    getFormWofSelector,
    getFormWofShadowSelector,
    getFormWofSliceTextSelector,
    getFormWofWinningSliceA11ySelector
} from '../elements';
import { DeviceType } from '../types';
import { getContext } from '../context';

const ROTATE_CYCLES_COUNT = 5;
const MIN_DEGREES_ROTATE_AMOUNT = 0.03;

const MIN_POINTER_ANGLE = -70;
const POINTER_INITIAL_DEGREES = 0;
const POINTER_MAX_ROTATED_DEGREES = MIN_POINTER_ANGLE;
const WAIT_BEFORE_FINISH = 500;

export const spinWheelOfFortune = (formID: string, blockID: string, winningSliceID: string): Promise<void> => {
    if (!winningSliceID) {
        return;
    }

    const slicesCount = document.querySelectorAll(getFormWofSliceTextSelector(formID, blockID)).length;
    const sliceDegrees = 360 / slicesCount;

    return new Promise((resolve) => {
        const rotor = document.querySelector(getFormWofSelector(formID, blockID));
        const rotorShadow = document.querySelector(getFormWofShadowSelector(formID, blockID));
        const pointer = document.querySelector(getFormWofPointerSelector(formID, blockID));
        const pointerShadow = document.querySelector(getFormWofPointerShadowSelector(formID, blockID));
        const degreesToRotate = getDegreesToRotate(formID, blockID, winningSliceID, sliceDegrees, slicesCount);

        const spin = (currentRotorDegrees: number, currentPointerDegrees: number): void => {
            const nextRotorDegrees = getNextRotorDegrees(currentRotorDegrees, degreesToRotate);
            const nextPointerDegrees = getNextPointerDegrees(nextRotorDegrees, currentPointerDegrees, sliceDegrees, slicesCount);
            rotateElement(rotor, nextRotorDegrees);
            rotateElement(rotorShadow, nextRotorDegrees);
            rotateElement(pointer, nextPointerDegrees);
            rotateElement(pointerShadow, nextPointerDegrees);
            if (nextRotorDegrees < degreesToRotate) {
                if (window.requestAnimationFrame) {
                    requestAnimationFrame(function () {
                        spin(nextRotorDegrees, nextPointerDegrees);
                    });
                } else {
                    setTimeout(function () {
                        spin(nextRotorDegrees, nextPointerDegrees);
                    }, 20);
                }
            } else {
                setTimeout(() => {
                    resolve();
                }, WAIT_BEFORE_FINISH);
            }
        };

        const wheelOfFortuneBlock = document.querySelector(getFormBlockContainerSelector(formID, blockID));
        wheelOfFortuneBlock?.scrollIntoView({ behavior: 'smooth' });
        announceWinningsA11y(formID, blockID, winningSliceID);
        spin(0, 0);
    });
};

const getDegreesToRotate = (formID: string, blockID: string, winningSliceID: string, sliceDegrees: number, slicesCount: number): number => {
    const winningSliceIndex = getWinningSliceIndex(formID, blockID, winningSliceID);
    const degreesToWinningSlice = sliceDegrees * (slicesCount - winningSliceIndex);
    return ROTATE_CYCLES_COUNT * 360 + degreesToWinningSlice;
};

const announceWinningsA11y = (formID: string, blockID: string, winningSliceID: string): void => {
    const sliceTextElements = Array.from(document.querySelectorAll(getFormWofSliceTextSelector(formID, blockID)));
    const winningSliceIndex = getWinningSliceIndex(formID, blockID, winningSliceID);
    const winningSliceEl = sliceTextElements[winningSliceIndex];
    const winningSliceA11y = document.querySelector(getFormWofWinningSliceA11ySelector(formID, blockID));

    let winningSliceText = sliceTextElements[winningSliceIndex]?.textContent?.trim();
    if (!winningSliceText) {
        const imgEl = winningSliceEl?.querySelector('img');
        winningSliceText = imgEl?.alt?.trim();
    }

    if (winningSliceA11y) {
        winningSliceA11y.textContent = `You've spun the Wheel of Fortune and won ${winningSliceText}. Please hold on for a moment while you're guided to the next step.`;
    }
};

const getWinningSliceIndex = (formID: string, blockID: string, winningSliceID: string): number => {
    const slicesIds = Array.from(document.querySelectorAll(getFormWofSliceTextSelector(formID, blockID))).map((slice) =>
        slice.getAttribute('id')
    );

    return slicesIds.indexOf(winningSliceID);
};

const getNextRotorDegrees = (currentRotorDegrees: number, degreesToRotate: number): number => {
    const frameSpeed = getContext().user.getDeviceType() === DeviceType.mobile ? 8 : 24;
    let degreesRotateAmount = (degreesToRotate - currentRotorDegrees) / frameSpeed / ROTATE_CYCLES_COUNT;

    if (degreesRotateAmount < MIN_DEGREES_ROTATE_AMOUNT) {
        degreesRotateAmount = MIN_DEGREES_ROTATE_AMOUNT;
    }

    const nextRotorDegrees = currentRotorDegrees + degreesRotateAmount;
    if (nextRotorDegrees + MIN_DEGREES_ROTATE_AMOUNT >= degreesToRotate) {
        return degreesToRotate;
    }

    return nextRotorDegrees;
};

const getNextPointerDegrees = (rotated: number, currentPointerDegrees: number, sliceDegrees: number, slicesCount: number): number => {
    const MIN_SLICES_COUNT = 3;
    const MAX_POINTER_DEGREES_START_RATIO = 0.42;
    const MIN_POINTER_DEGREES_MIDDLE_RATIO = 0.61;
    const POINTER_DEGREES_START_RATIO_INCREASE_STEP = 0.01;
    const POINTER_DEGREES_MIDDLE_RATIO_INCREASE_STEP = 0.022;

    const additionalSlicesCount = slicesCount - MIN_SLICES_COUNT;
    const pointerDegreesStartRatio = MAX_POINTER_DEGREES_START_RATIO - additionalSlicesCount * POINTER_DEGREES_START_RATIO_INCREASE_STEP;
    const pointerDegreesMiddleRatio = MIN_POINTER_DEGREES_MIDDLE_RATIO + additionalSlicesCount * POINTER_DEGREES_MIDDLE_RATIO_INCREASE_STEP;

    const pointerDegreesStart = sliceDegrees * pointerDegreesStartRatio;
    const pointerDegreesMiddle = sliceDegrees * pointerDegreesMiddleRatio;
    const sliceRotated = rotated % sliceDegrees;

    // start pointer rotation
    if (sliceRotated > pointerDegreesStart && sliceRotated < pointerDegreesMiddle) {
        const pointerDegreesPercents = (sliceRotated - pointerDegreesStart) / (pointerDegreesMiddle - pointerDegreesStart);
        const degreesDelta = Math.abs(POINTER_INITIAL_DEGREES - POINTER_MAX_ROTATED_DEGREES) * pointerDegreesPercents;

        return POINTER_INITIAL_DEGREES - degreesDelta;
    }

    // rotate pointer back
    const nextPointerDegrees = currentPointerDegrees + 7;
    if (nextPointerDegrees > POINTER_INITIAL_DEGREES) {
        return POINTER_INITIAL_DEGREES;
    }

    return nextPointerDegrees;
};

const rotateElement = (element: Element, degrees: number): void => {
    element?.setAttribute('style', `transform: rotate(${degrees}deg)`);
};
