import { trackNotViewedForm } from '../tracking';
import { FormData } from '../types';
import { hideElement } from '../utils';
import { getFirst } from './utils';

const focusEventTimes: number[] = [];
const FOCUS_THRESHOLD_MS = 50; // Time window to detect rapid focus changes
const FOCUS_EVENT_LIMIT = 5; // Number of focus events within the threshold to consider a conflict

const detectFocusTrapConflict = (handleFocusTrapConflict: () => void): void => {
    const now = Date.now();
    focusEventTimes.push(now);

    if (focusEventTimes.length > FOCUS_EVENT_LIMIT) {
        focusEventTimes.shift();
    }

    if (focusEventTimes.length === FOCUS_EVENT_LIMIT) {
        const timeDiff = focusEventTimes[FOCUS_EVENT_LIMIT - 1] - focusEventTimes[0];

        if (timeDiff < FOCUS_THRESHOLD_MS) {
            handleFocusTrapConflict();
        }
    }
};

export function createFocusTrap({
    element,
    focusableElements,
    formData,
    cleanupFocusTrapInitiator
}: {
    element: HTMLElement;
    focusableElements: HTMLElement[];
    formData: FormData;
    cleanupFocusTrapInitiator: () => void;
}): () => void {
    const handleFocusTrapConflict = (): void => {
        cleanupFocusHandler();
        cleanupFocusTrapInitiator();
        hideElement(element);
        // TODO: To be removed when focus trap conflicts issue is fully resolved
        trackNotViewedForm(formData);
    };

    const handler = (evt: FocusEvent): unknown => {
        detectFocusTrapConflict(handleFocusTrapConflict);

        if (!('tagName' in evt.target)) {
            getFirst(focusableElements)?.focus({ preventScroll: true });
            return;
        }
        if (element.contains(evt.target as HTMLElement)) {
            return;
        }

        evt.preventDefault();
        evt.stopPropagation();
        evt.stopImmediatePropagation();
        getFirst(focusableElements)?.focus({ preventScroll: true });
    };

    const originalFocus = document.onfocus;

    document.onfocus = handler;
    document.addEventListener('focus', handler, true);
    document.addEventListener('focus', handler);

    const cleanupFocusHandler = (): void => {
        document.onfocus = originalFocus;
        document.removeEventListener('focus', handler, true);
        document.removeEventListener('focus', handler);
    };

    return cleanupFocusHandler;
}
