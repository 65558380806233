import { focusFormContent } from '../../accessibility';
import { submitBackInStock, subscribeForm } from '../../api';
import { getFormLoadingContainer, getSubmitFormButtonSelector } from '../../elements';
import { ActionType, Form, SubscribeFormData } from '../../types';
import { hideElement, markFormFilled, showElement } from '../../utils';
import { initCountdownTimer } from '../../components/countdown-timer';
import { hideCurrentStep, proceedToSubmittedState, showNextStep } from '../next-step';
import { spinWheelOfFortune } from '../spin';
import { dispatchFormSubmitEvents } from './submit-event';
import { resetFormSubmitErrorState, handleSubmitError } from './error-handling';

const showLoader = (formID: string): void => {
    const loader = document.querySelector(getFormLoadingContainer(formID));
    showElement(loader);
};

const hideLoader = (formID: string): void => {
    const loader = document.querySelector(getFormLoadingContainer(formID));
    hideElement(loader);
};

const disableSubmitButton = (formID: string, formElement: HTMLElement): void => {
    const submitButton = formElement.querySelector(getSubmitFormButtonSelector(formID));
    if (submitButton) {
        submitButton.setAttribute('disabled', 'true');
    }
};

const enableSubmitButton = (formID: string, formElement: HTMLElement): void => {
    const submitButton = formElement.querySelector(getSubmitFormButtonSelector(formID));
    if (submitButton) {
        submitButton.removeAttribute('disabled');
    }
};

export const submitAction = async (form: Form, formElement: HTMLElement, subscribeFormData: SubscribeFormData): Promise<void> => {
    const { id: formID, actions, mainFormId } = form.data;
    const fields = form.getCurrentStepFields();
    const currentStepID = form.getCurrentStepID();

    resetFormSubmitErrorState(fields, formID, formElement);
    showLoader(formID);
    disableSubmitButton(formID, formElement);

    const submitRequest = form.isBackInStock ? submitBackInStock : subscribeForm;

    try {
        const subscribeResult = await submitRequest(form, subscribeFormData);

        dispatchFormSubmitEvents(form, subscribeFormData);

        form.saveSubscribeResult(subscribeResult);

        const { discount } = subscribeResult;
        if (discount) {
            form.saveDiscountCode(discount.code);
        }

        markFormFilled(mainFormId || formID);
        hideLoader(formID);

        const spinAction = actions.find((action) => action.type === ActionType.spin);
        const isWofInCurrentStep = spinAction?.settings?.stepID === currentStepID;

        if (isWofInCurrentStep) {
            await spinWheelOfFortune(formID, spinAction.targetID, discount?.sliceID);
        }

        hideCurrentStep(form);

        if (!form.isLastStep()) {
            showNextStep(form);
        } else {
            proceedToSubmittedState(form);
        }

        initCountdownTimer(form.element, form.data);
        focusFormContent(formID);
    } catch (error: unknown) {
        hideLoader(formID);
        enableSubmitButton(formID, formElement);

        handleSubmitError(error, fields, formID, formElement);
    }
};
