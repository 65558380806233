import { FORMS_TRACKING_PIXELS_CONTAINER_ID } from './constants';
import { getContext } from './context';
import { dispatchInteractionEvent, dispatchStepInteractionEvent } from './event-dispatchers';
import { Form, FormData, Step } from './types';

type InteractedForm = {
    interacted: boolean;
    firstStepInteracted: boolean;
    secondStepInteracted: boolean;
};

const interactedFormsById: Record<string, InteractedForm> = {};

export const setFormInteracted = (formId: string): void => {
    interactedFormsById[formId] = {
        ...interactedFormsById[formId],
        interacted: true
    };
};

export const setFormStepInteracted = (formId: string, step: Step): void => {
    const stepsInteraction = step === '1' ? { firstStepInteracted: true } : { secondStepInteracted: true };

    interactedFormsById[formId] = {
        ...interactedFormsById?.[formId],
        ...stepsInteraction
    };
};

export const resetFormInteracted = (formId: string): void => {
    interactedFormsById[formId] = {
        interacted: undefined,
        firstStepInteracted: undefined,
        secondStepInteracted: undefined
    };
};

const isInteractedForm = (formId: string): boolean => interactedFormsById[formId]?.interacted;
const isInteractedFormStep = (formId: string, step: Step): boolean => {
    return step === '1' ? interactedFormsById[formId]?.firstStepInteracted : interactedFormsById[formId]?.secondStepInteracted;
};
const addTrackingPixel = (url: string): Promise<void> =>
    new Promise((resolve, reject) => {
        const pixel = document.createElement('img');
        pixel.setAttribute('src', url);
        pixel.setAttribute('alt', '');

        pixel.addEventListener('load', () => {
            resolve(null);
            pixel.remove();
        });

        pixel.addEventListener('error', () => {
            reject();
        });

        const trackingPixelsContainer = document.getElementById(FORMS_TRACKING_PIXELS_CONTAINER_ID);

        trackingPixelsContainer.appendChild(pixel);
    });

export const createTrackingContainer = (): HTMLElement => {
    const formsTrackingPixelsContainer = document.createElement('div');
    formsTrackingPixelsContainer.setAttribute('id', FORMS_TRACKING_PIXELS_CONTAINER_ID);

    return formsTrackingPixelsContainer;
};

export const trackView = (form: FormData): Promise<void> => addTrackingPixel(getContext().forms.getTrackViewEndpoint(form));
export const trackNotViewedForm = (form: FormData): Promise<void> =>
    addTrackingPixel(getContext().forms.getTrackNotViewedFormEndpoint(form));
export const trackAddedInvalidEmbeddedForm = (form: FormData): Promise<void> =>
    addTrackingPixel(getContext().forms.getTrackAddedInvalidEmbeddedFormEndpoint(form));

const handleMultiStepInteractionEvent = (form: Form): void => {
    const step: Step = form.isLastStep() ? '2' : '1';

    if (isInteractedFormStep(form.data.id, step)) {
        return;
    }

    dispatchStepInteractionEvent({ formID: form.data.id, step });
    form.timeFormInteracted = Date.now();
    setFormStepInteracted(form.data.id, step);
};

export const trackInteraction = (form: Form): Promise<void> => {
    if (form.isMultistep) {
        handleMultiStepInteractionEvent(form);
    }

    if (isInteractedForm(form.data.id)) {
        return;
    }

    dispatchInteractionEvent({ formID: form.data.id });
    form.timeFormInteracted = Date.now();

    setFormInteracted(form.data.id);
    return addTrackingPixel(getContext().forms.getTrackInteractionEndpoint(form.data));
};
