import {
    getFormSubmitErrorElement,
    getFormFieldElement,
    getFormFieldErrorElement,
    getFormFieldErrorElementByErrorType,
    getFormFieldRequiredErrorElement,
    getFormFieldVisualElements,
    getFormNextStepErrorElement
} from '../../elements';
import { Field, SubscribeBadRequest, isSubscribeBadRequest } from '../../types';
import {
    hideElement,
    showElement,
    addClassNameToElement,
    removeClassNameFromElement,
    setElementAttributes,
    removeElementAttributes
} from '../../utils';

const showGenericError = (formID: string, formElement: HTMLElement): void => {
    const formSubmitErrorEl = getFormSubmitErrorElement({ parentElement: formElement, formID });
    showElement(formSubmitErrorEl);
};

const invalidateFormFields = ({ details }: SubscribeBadRequest, invalidFields: Field[], formID: string, formElement: HTMLElement): void => {
    invalidFields.forEach((invalidField) => {
        getFormFieldVisualElements({
            parentElement: formElement,
            formID,
            fieldID: invalidField.targetID,
            fieldName: invalidField.name
        }).forEach((formField) => addClassNameToElement(formField, 'error'));

        const fieldErrorType = details[invalidField.name];
        const formFieldErrorElement = getFormFieldErrorElementByErrorType(
            { parentElement: formElement, formID, fieldID: invalidField.targetID },
            fieldErrorType
        );

        showElement(formFieldErrorElement);

        const invalidFormFieldElement = getFormFieldElement({
            parentElement: formElement,
            formID,
            fieldID: invalidField.targetID,
            fieldName: invalidField.name
        });

        setElementAttributes(invalidFormFieldElement, [
            { key: 'aria-invalid', value: 'true' },
            { key: 'aria-errormessage', value: formFieldErrorElement?.id }
        ]);
    });
};

export const resetFormSubmitErrorState = (fields: Field[], formID: string, formElement: HTMLElement): void => {
    fields.forEach((field) => {
        const formFieldElement = getFormFieldElement({
            parentElement: formElement,
            formID,
            fieldID: field.targetID,
            fieldName: field.name
        });

        removeElementAttributes(formFieldElement, ['aria-invalid', 'aria-errormessage']);
        hideElement(getFormFieldErrorElement({ parentElement: formElement, formID, fieldID: field.targetID }));
        hideElement(getFormFieldRequiredErrorElement({ parentElement: formElement, formID, fieldID: field.targetID }));

        getFormFieldVisualElements({ parentElement: formElement, formID, fieldID: field.targetID, fieldName: field.name }).forEach(
            (formField) => {
                removeClassNameFromElement(formField, 'error');
            }
        );
    });

    const formSubmitErrorEl = getFormSubmitErrorElement({ parentElement: formElement, formID });
    hideElement(formSubmitErrorEl);
};

export const resetFormNextStepErrorState = (formID: string, formElement: HTMLElement): void => {
    const formNextStepErrorEl = getFormNextStepErrorElement({ parentElement: formElement, formID });
    hideElement(formNextStepErrorEl);
};

export const handleSubmitError = (error: unknown, fields: Field[], formID: string, formElement: HTMLElement): void => {
    if (!isSubscribeBadRequest(error)) {
        showGenericError(formID, formElement);
        return;
    }

    const { details }: SubscribeBadRequest = error;

    const invalidFields = fields.filter((field) => {
        return !!details[field.name];
    });

    if (!invalidFields.length) {
        showGenericError(formID, formElement);
        return;
    }

    invalidateFormFields(error, invalidFields, formID, formElement);

    const firstInvalidElement = getFormFieldElement({
        parentElement: formElement,
        formID,
        fieldID: invalidFields[0].targetID,
        fieldName: invalidFields[0].name
    });
    firstInvalidElement?.focus();
};

export const handleNextStepError = (formID: string, formElement: HTMLElement): void => {
    const formNextStepErrorEl = getFormNextStepErrorElement({ parentElement: formElement, formID });
    showElement(formNextStepErrorEl);
};
